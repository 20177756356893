/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {x} from '@xstyled/styled-components';
import {Image, Quote, QuoteAuthor} from "smooth-doc/components";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "business-domain-modeling",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#business-domain-modeling",
    "aria-label": "business domain modeling permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Business Domain Modeling"), "\n", React.createElement(Quote, null, React.createElement(_components.p, null, "A good model is a foundation that can accommodate change in the direction and perspective of business experts.")), "\n", React.createElement(_components.p, null, "ZenWave 360º Business Domain Modeling is sustained in three pillars:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Understand the Mental Model"), " of the Business Experts."), "\n", React.createElement(_components.li, null, "Use ", React.createElement("strong", {
    className: "orange"
  }, "ZDL Model Language"), " to create and Ubiquitous Language that reflects the mental model of the Business Experts."), "\n", React.createElement(_components.li, null, "Use ", React.createElement("strong", {
    className: "orange"
  }, "ZenWave SDK"), " to convert the ZDL Model ", React.createElement("strong", {
    className: "orange"
  }, "into different Software Artifacts"), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "understand-the-mental-model-of-the-business-experts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understand-the-mental-model-of-the-business-experts",
    "aria-label": "understand the mental model of the business experts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understand the mental model of the business experts"), "\n", React.createElement(_components.p, null, "This approach emphasizes ", React.createElement("strong", {
    className: "orange"
  }, "the importance of understanding the business domain"), " in order to create effective software."), "\n", React.createElement(_components.p, null, "The first step is to understand the mental model of the business experts because ", React.createElement("strong", {
    className: "orange"
  }, "all requirements and assumptions will come from this mental model"), ". Embedding the perspective of domain experts into your model is by far the most important aspect to create good maintainable software."), "\n", React.createElement(_components.p, null, "If we don't get right the mental model of business experts, no amount of ", React.createElement("strong", {
    className: "orange"
  }, "Clean Code"), ", ", React.createElement("strong", {
    className: "orange"
  }, "Clean Architecture"), ", ", React.createElement("strong", {
    className: "orange"
  }, "Hexagonal Architecture"), ", etc... will help in creating good and maintainable software."), "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Event-Storming"), " is the method we employ to grasp the mental model of business experts. Because it's technological low entry barrier, and because is a ", React.createElement("strong", {
    className: "orange"
  }, "perfect fit for modeling Event-Driven Architectures"), "."), "\n", React.createElement(_components.h2, {
    id: "create-and-ubiquitous-language-to-reflect-the-mental-model-of-the-business-experts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#create-and-ubiquitous-language-to-reflect-the-mental-model-of-the-business-experts",
    "aria-label": "create and ubiquitous language to reflect the mental model of the business experts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Create and Ubiquitous Language to reflect the mental model of the Business Experts"), "\n", React.createElement(Quote, null, React.createElement(_components.p, null, "It is not the domain experts’ knowledge that goes to production, it is the assumption of the developers.\r\n", React.createElement(QuoteAuthor, null, "- Alberto Brandolini"))), "\n", React.createElement(_components.p, null, "The goal of Ubiquitous Language is to create a common language that reduces misunderstandings and improves communication between technical and non-technical team members."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Shared and Common"), ": The language is shared by all team members, including developers, domain experts, and stakeholders. It's used in all forms of communication, from meetings and emails to code and documentation."), "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Model-Based"), ": The language is based on the domain model. It reflects the structures and relationships in the model."), "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Explicit"), ": The language is explicit, meaning that each term has a specific and clear definition. This reduces ambiguity and misunderstanding."), "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Used in Code"), ": The language is used in the codebase. Class names, method names, variable names, etc., should all be based on the Ubiquitous Language."), "\n"), "\n", React.createElement(_components.p, null, "ZenWave ", React.createElement("strong", {
    className: "orange"
  }, "ZDL Modeling Language"), " is a Domain Specific Language that allows you to create a Conceptual Model that reflects the mental model of the business experts following DDD and Event-Driven Architecture principles."), "\n", React.createElement(_components.p, null, "It can ", React.createElement("strong", {
    className: "orange"
  }, "map the discoveries of an Event-Storming session"), ", retaining the language of business experts. It's ", React.createElement("strong", {
    className: "orange"
  }, "designed to be developer friendly"), ", and because it can be ", React.createElement("strong", {
    className: "orange"
  }, "converted into different software artifacts"), ", it will propagate that Common Language to code, tests, api definitions..."), "\n", React.createElement(_components.p, null, "-> As a technical expert you can use this ZDL Modeling Language to communicate within your team, and narrate the story expressed by the model back to domain experts in their own language."), "\n", React.createElement(_components.h2, {
    id: "convert-the-ubiquitous-language-into-different-software-artifacts-with-zenwave-sdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#convert-the-ubiquitous-language-into-different-software-artifacts-with-zenwave-sdk",
    "aria-label": "convert the ubiquitous language into different software artifacts with zenwave sdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Convert the Ubiquitous Language into different Software Artifacts with ZenWave SDK"), "\n", React.createElement(Quote, null, React.createElement(_components.p, null, "The best way to validate a conceptual model is to implement it.")), "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Working software"), " is the best way to evaluate the validity of a conceptual model. It allows for ", React.createElement("strong", {
    className: "orange"
  }, "early feedback"), " from all members of the development team, including developers, testers, and others, which can be crucial for the iterative refinement of the model."), "\n", React.createElement(_components.p, null, "ZenWave SDK is ", React.createElement("strong", {
    className: "orange"
  }, "a set of tools"), " that allows you to ", React.createElement("strong", {
    className: "orange"
  }, "convert the Ubiquitous Language"), " into different software artifacts, like:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "API Definitions:"), " AsyncAPI v2/v3, OpenAPI..."), "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Drawings and Documentation:"), " PlantUML, Mermaid, Markdown..."), "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "Multiple Backend Implementations"), " for Spring-Boot, Spring-Cloud, JPA, MongoDB..."), "\n", React.createElement(_components.li, null, React.createElement("strong", {
    className: "orange"
  }, "API-First Code"), " for AsyncAPI and OpenAPI"), "\n", React.createElement(_components.li, null, "\n", React.createElement("strong", {
    className: "orange"
  }, "API-Testing..."), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement("strong", {
    className: "orange"
  }, "Automatically propagating the Ubiquitous Language across code"), " and providing the building blocks to put together a working implementation of your domain in no time."), "\n", React.createElement(_components.h2, {
    id: "practical-event-storming-with-asyncapi-v3-and-zenwave-sdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#practical-event-storming-with-asyncapi-v3-and-zenwave-sdk",
    "aria-label": "practical event storming with asyncapi v3 and zenwave sdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Practical Event Storming with AsyncAPI v3 and ZenWave SDK"), "\n", React.createElement("iframe", {
    width: "1050",
    height: "591",
    src: "https://www.youtube.com/embed/-uHONzz-a6U?si=rakq_rMn_zd7N0DI",
    title: "Practical Event Storming with AsyncAPI v3 and ZenWave SDK - Ivan Del Viejo, Ivan Garcia Sainz-Aja",
    frameborder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen",
    allowfullscreen: true
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
